import { useContext } from "react";
import { usePermissions } from "react-admin";
import { RbacContext } from "../contexts/rbac/index.js";
export const useGetCanActivate = () => {
  const canActivate = useContext(RbacContext);
  const {
    loaded,
    permissions
  } = usePermissions();
  return {
    canActivate: !!permissions ? (resource, action, field) => canActivate(permissions, resource, action, field) : null,
    loaded: loaded && !!permissions
  };
};